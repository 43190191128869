




































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class KNG00010Login extends Vue {
  loginSyainNo: string = '';
  password: string = '';
  isShowTimeout = false;
  csrfToken = '';
  // スナックバー設定
  showSnackbar: boolean = false;
  SnackbarMessage: string = '';

  /** mounted */
  mounted(): void {
    // URL パラメータを取得 timeoutの場合はタイムアウトを表示
    // eslint-disable-next-line no-restricted-globals
    const pair: string[] = window.location.search.substring(1).split('&');
    for (let i = 0; pair[i]; i += 1) {
      if (pair[i] === 'timeout') {
        this.isShowTimeout = true;
      }
      if (pair[i] === 'error') {
        this.SnackbarMessage = 'ログインに失敗しました。';
        this.showSnackbar = true;
      }
    }
    // CSRFトークンをポストパラメータとしてフォームにセット
    this.csrfToken = this.getCsrfTokenByCookie();
  }

  /**
   * CSRFトークンをクッキーから取得する
   */
  getCsrfTokenByCookie(): string {
    // eslint-disable-next-line no-useless-escape
    const cookie: string = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    return cookie;
  }
  /**
   * ログインボタン押下時
   */
  login(e: any): void {
    if (this.loginSyainNo === '') {
      this.SnackbarMessage = '社員番号を入力してください。';
      this.showSnackbar = true;
      e.preventDefault();
    } else if (this.password === '') {
      this.SnackbarMessage = 'パスワードを入力してください。';
      this.showSnackbar = true;
      e.preventDefault();
    }
  }
}
